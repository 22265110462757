<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0" v-if="data">
			<h3 class="card-title font-weight-bolder text-dark">iDirect Heat Map</h3>
		</div>
		<div class="card-body px-5 pt-0">
			<div class="row">
				<div class="col-md-6 fade-element-in">
					<h3 class="pl-5">Downstream</h3>
					<heatmap :data="data" :id="networkId" dir="rx" />
				</div>
				<div class="col-md-6 fade-element-in">
					<h3 class="pl-5">Upstream</h3>
					<heatmap :data="data" :id="networkId" dir="tx" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 text-center">Last 60 days : Total traffic within each hour</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NetworkHeatMap',
	components: { heatmap: () => import('@/view/content/lib/heatmap.vue') },
	props: ['serverId', 'networkId'],
	data() {
		return {
			loaded: false,
			data: null,
		};
	},
	created() {
		this.loaded = false;
		this.$http.get(`idirect/network/${this.serverId}/${this.networkId}/heatmap`).then(resp => {
			this.data = resp.data.data;
			this.loaded = true;
		});
	},
};
</script>

<style></style>
